import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "published_at": "2020-05-25T10:32:47.871Z",
  "featuredImage": "sample.jpg",
  "title": "Sample",
  "kurzbeschreibung": "Jetzt ziemlich cool die Füße hochlegen in der neuen Wohnung in der Schweiz",
  "date": "sample",
  "adresse": "Samplestr. 42",
  "plz": "10245",
  "ort": "Sampling",
  "zimmer": "04",
  "m": "72",
  "baujahr": "2019",
  "homegateLink": "https://www.homegate.ch/mieten/3000265757"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`hier kann ganz viel Text als Erklärung stehen!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      